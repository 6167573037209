<template>
  <div class="ui-action">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        动作管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增动作</el-button>
      </div>
      <!-- 当前接口信息展示 -->
      <el-table :data="tableDataAction" style="width: 100%">
        <el-table-column
          prop="action_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="action_name"
          label="动作名称">
        </el-table-column>
        <el-table-column
          prop="action_key"
          label="动作key">
        </el-table-column>
        <el-table-column
          prop="action_type"
          label="动作类型">
        </el-table-column>
        <el-table-column
          prop="description"
          label="动作描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="动作状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleAction"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormAction" :rules="FormRulesAction" ref="ruleFormRefAction" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="页面ID">
          <el-input v-model="ruleFormAction.action_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="动作名称" prop="action_name">
          <el-input v-model="ruleFormAction.action_name"></el-input>
        </el-form-item>
        <el-form-item label="动作字段" prop="action_name">
          <el-input v-model="ruleFormAction.action_key"></el-input>
        </el-form-item>
        <el-form-item label="操作类型" prop="action_name">
          <!-- <el-input v-model="ruleFormAction.action_type"></el-input> -->
          <el-select v-model="ruleFormAction.action_type" placeholder="请选择操作类型" filterable clearable>
            <el-option
              v-for="item in selectOptionsType"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面状态" prop="action_name">
          <el-switch v-model="ruleFormAction.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="页面描述" prop="description">
          <el-input type="textarea" v-model="ruleFormAction.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAction = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAddAction">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEditAction">编 辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryActionList, addAction, editAction, deleteAction } from '@/services/uiAction'
export default {
  name: 'UiAction',
  data () {
    return {
      isAdd: true,
      tableDataAction: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      dialogVisibleAction: false,
      ruleFormAction: {},
      FormRulesAction: {
        action_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      },
      selectOptionsType: [
        {
          id: '1',
          label: '页面操作'
        }, {
          id: '2',
          label: '元素操作'
        }
      ]
    }
  },
  created () {
    this.loadGetActionList()
  },
  methods: {
    async loadGetActionList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryActionList(queryObj)
      if (res.status === 200) {
        this.tableDataAction = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetActionList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetActionList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      console.log('新增动作')
      this.isAdd = true
      this.ruleFormAction = {}
      this.dialogVisibleAction = true
    },
    handleEdit (rowData) {
      console.log('编辑动作')
      this.isAdd = false
      this.ruleFormAction = rowData
      this.dialogVisibleAction = true
    },
    handleDel (rowData) {
      console.log('删除动作')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { action_id: rowData.action_id }
        const { data: res } = await deleteAction(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除用例成功')
          this.loadGetActionList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAddAction () {
      const addObj = this.ruleFormAction
      const { data: res } = await addAction(addObj)
      if (res.status === 200) {
        this.dialogVisibleAction = false
        this.loadGetActionList()
        this.$message.success('添加成功')
      }
    },
    async processEditAction () {
      const editObj = this.ruleFormAction
      const { data: res } = await editAction(editObj)
      if (res.status === 200) {
        this.dialogVisibleAction = false
        this.loadGetActionList()
        this.$message.success('编辑成功')
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefAction.resetFields()
      this.loadGetActionList()
    }
  }
}
</script>

<style>

</style>
