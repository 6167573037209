import request from '@/utils/request.js'
import { urlPath } from './uiPage'

// 查询步骤动作信息
export const queryActionList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiAction',
    params: {
      ...data
    }
  })
}

// 依据ID查询动作信息
export const queryActionById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiAction',
    params: {
      ...data
    }
  })
}

// 查询所有的动作信息
export const queryActionAll = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiAction',
    params: {
      ...data
    }
  })
}

// 添加动作信息
export const addAction = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiAction',
    data
  })
}

// 编辑动作信息
export const editAction = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiAction',
    data
  })
}

// 删除动作信息
export const deleteAction = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiAction',
    data
  })
}
